div.points {
	height: 310px;
	width: 100%;
	background-color: $point-gray;
}

div.map {
	height: 100%;
	width: 100%;
	background: url('../img/terkep3.svg');
	background-size: 100%;
	padding-top: 80px;
	padding-bottom: 200px;
	transition: 0.6s all;

	div.wrap {
		text-align: center;

		div.topic {
			width: 940px;
			height: 630px;
			background-color: $white;
			border-top: 1px solid $red;
			padding: 70px 100px 70px 100px;
			display: block;
			display: none;

			&.show {
				display: block;
			}

			div.first-page-picture {
				width: 350px;
				height:573px;
				background-color: purple;
				position: absolute;
				right: 80px;
				bottom: -120px;
				background: url('../img/first_page_picture.jpg');
			}

			p {
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 30px;
				word-break: initial;
				width: auto;
				max-width: calc(100% - 400px);
			}
		}

		div.first-page-button {
			display: flow-root;
			padding-top: 60px;
		}

		.nav-right {
			position: absolute;
			top: 0px;
			right: -160px;
		}

		.nav-left {
			position: absolute;
			top: 0px;
			left: -160px;

			.button.menu {
				text-align: left;
			}
		}
	}
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to  {
  opacity: 0;
}

.canvas-container {
  margin-top: 30px; 
  margin-bottom: 30px;
  text-align: center;
}