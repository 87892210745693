.eye{
  border-radius: 100%;
  position: relative;
  width: 30px; 
  height: 30px;  
  background-color: $light-gray;
  display: inline-block;

	&:hover {
		background-color: $background-gray;
	}
}

.bogar {
	border-radius: 100%;
 	position: relative;
 	width: 13px; 
 	height: 13px;  
 	background-color: red;
 	margin: auto;
 	margin-top: 7px;
}

.bontas {
	margin-left: 50%;
}

.left {
	left: 100px;
}

.container {
	text-transform: uppercase;
	font-size: 14px !important;
	padding-top: 6px;
	display: inline-block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	font-size: 14px;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 30px;
	width: 30px;
	background-color: $light-gray;
	border-radius: 50%;
}

.container:hover input ~ .checkmark {
	background-color: $background-gray;
}

.container input:checked ~ .checkmark {
	background-color: $light-gray;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.container input:checked ~ .checkmark:after {
	display: block;
	background-color: red;
	height: 14px;
	width: 14px;
}

.container .checkmark:after {
 	top: 8px;
	left: 8px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
	color: red;
}

.left {
	margin-left:50px;
}