.checkout {
	h2 {
		text-align: center;
	}

	form,
	.form-group {
		max-width: 400px;
		margin: auto;
		position: relative;


	}

	form.shipping-address {
		margin-bottom: 100px;
	}

	.mode,
	.payment {
		margin-bottom: 100px;
		display: grid;
		grid-column-gap: 0px;
		grid-row-gap: 0px;
		grid-template-columns: 1fr 1fr;
	}

	.mode > div,
	.payment > div {
		padding: 50px;
		border: 1px solid $background-gray;
		text-align: center;
		cursor: pointer;
		margin-right: -2px;
		background: white;

		&:hover,
		&.active,
		&.active:hover {
			border:2px solid $red;
			padding: 49px;
			position: relative;
			z-index: 99;
			box-sizing: border-box;
			flex-grow: 1;
    		flex-basis: 0;

			.pikto {
				filter: saturate(1);
				opacity: 1;
			}
		}

		&:hover {
			border-color: $background-gray;
			z-index: 9;
		}

		.pikto {
			height: 90px;
			background: url(../img/delivery.svg)no-repeat center;
			background-size: contain;
			margin-bottom: 20px;
			filter: saturate(0);
			opacity: 0.7;
		}

		strong {
			display: block;
			padding: 30px 0 15px;
			font-weight: 700;
		}

		p {
			text-align: left;
			font-size: 12px;
		}
	}

	.payment {
		grid-template-columns: 1fr 1fr 1fr;
		position: relative;

		&:before,
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			left: 0;
			top: 1px;
			background: white;
			height: 20px;
			z-index: 1;
		}

		&:after {
			top: auto;
			bottom: 1px;
		}

		.soon {
			position: relative;
			pointer-events: none;

			.pikto {
				opacity: 0.2;
			}

			&:after {
				content: 'HAMAROSAN';
				position: absolute;
				transform: rotate(-30deg);
				left: 0;
				right: 0;
				margin: auto;
				top: 80px;
				font-size: 27px;
			}

			&:hover {
				padding: 50px;
				border: 1px solid $background-gray;
				z-index: 0;

				.pikto {
					opacity: 0.2;
					filter: saturate(0);
				}
			}
		}
	}

	.autocomplete-results {
		position: absolute;
		background: $white;
		//border: $border;
		z-index: 99;
		list-style: none;
		width: 100%;
		max-width: 400px;
		margin-top: -20px;

		li {
			background-color: #eee;
			cursor: pointer;
			padding: 0px 15px;
			font-size: 14px;

			&:hover {
				background-color: #ddd;
			}

			&:first-child {
				padding-top: 5px;

			}

			&:last-child {
				padding-bottom: 5px;
			}
		}
	}
}

.thanks,
.contact {
	background: url(../img/thankyou.jpg)no-repeat center;
	background-size: cover;
	height: 100%;
	width: 100%;
	position: absolute;

	.center-center {
		width: 350px;
		height: 250px;
		color: $white;

		p {
			color: $white;
		}

		h1 {
			color: $white;
			text-align: center;
		}

		.button {
			background: $white;
			margin-top: 40px;
			color: $black;
			text-transform: uppercase;
			display: inline-block;	
			padding: 0 25px;
		}
	}
}