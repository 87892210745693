.range {
	height: 3px;
	background: $stripe-gray;
	display: inline-block;
	width: calc(100% - 65px);
	vertical-align: middle;
	margin-right: 6px;

	.avaliable {
		height: 100%;
		width: 100%;
		background: $red;
	}
}