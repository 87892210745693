table.cart {
	width: 100%;
	max-width: 1180px;
	margin: auto;
	//border-collapse: collapse;
	border-spacing: 14px;
	table-layout: fixed;
	text-align: center;
	margin-top: 50px;


	th {
		text-align: center;

		&:nth-child(4) {
			width: 30px;
		}
	}

	td {
		border: 0;
		border-right: 1px solid $background-gray;
		text-align: center;
		height: 30px;
		padding: 0;
		font-size: 18px;
		letter-spacing: 0;

		&:nth-child(3)::before {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			height: 1px;
			background: $background-gray;
			display: block;
			margin-top: -10px;
		}

		&:nth-child(4) {
			width: 30px;
			border-right: 1px solid $background-gray;

			svg {
				position: absolute;
				cursor: pointer;
				margin: -8px 0 0 -14px;

				&:hover {
					color: $red;
				}
			}
		}

		&:first-child,
		&:nth-child(2) {
			color: $silver;
		}

		&:first-child {
			border-left: 1px solid $background-gray;
			text-transform: uppercase;

			span {
				text-transform: none;
				color: $silver;
				font-size: 16px;
				font-weight: 300;
			}
		}
	}

	tr:last-child td {
		border: 0;
		font-weight: 800;
	}
}

.mini-cart {
	position: fixed;
	right: -94px;
	top: calc(50% - 50px);
	background: $gray;
	width: 94px;
	height: 80px;
	z-index: 999;
	cursor: pointer;
	color: white;
	text-align: center;
	font-size: 30px;
	padding-top: 20px;
	transition: 0.2s all;

	p {
		color: $white;
		font-size: 14px;

		b {
		 	color: $white;
		}
	}

	&.close {
		right: -94px;
		transition: 0.5s all;
	}

	&.open {
		right: 0px;
		transition: 0.5s all;
	}
}