body {
	background: $light-grey;
	padding: 8px 10px 10px;
	font-family: 'Open Sans', sans-serif;
}

.wrap {
	position: relative;
	width: 100%;
	max-width: 1140px;
	margin: auto;

	&.mini {
		max-width: 400px;
	}
}

main {
	margin-bottom: 10px;
	min-height: calc(100vh - 300px);
	background: $white;
	position: relative;
	overflow: hidden;
}

footer {
	height: 130px;
	background: $gray;

	.center {
		text-align: center;
		color: $white;
		opacity: 0.7;
		line-height: 130px;
		font-size: 12px;

		b {
			color: $white;
		}
	}

	.right {
		position: absolute;
		right: 0px;
		line-height: 130px;
		font-size: 10px;
		color: $white;
		opacity: 0.5;
		text-transform: uppercase;
		padding-right: 70px;
		z-index: 1;

		a.dyb {
			display: inline-block;
			width: 68px;
			height: 52px;
			background: url(../img/dyb.svg)no-repeat center;
			background-size: contain;
			opacity: 0.6;
			vertical-align: middle;
			margin-left: 25px;
			margin-top: -2px;

			&:hover {
				opacity: 1;
			}
		}
	}
}

.center {
	text-align: center;
}

.center-center {
	text-align: center;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
}