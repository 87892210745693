.cards {
	margin-top: -21px;
	padding-top: 100px;
	position: relative;
	display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card-cow {
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 100;
	background-color: $white;
	display: inline-block;
	padding-bottom: 0;
	text-align: center;

	div.pikto-cow {
		width: 110px;
		height: 92px;
		margin: auto;
		margin-top: 5px;
		margin-bottom: 27px;
		background: url('../img/kisdaraboltmarha_dupla.svg') 0 0;
		background-clip: content-box;
		background-repeat: no-repeat;
		background-size: cover;
	}

	&.selected {
		background-color: $select-gray;

		td:nth-child(2) {
		border-left: 1px solid $border-gray;
		}

		td {
			border-top: 1px solid $border-gray !important;
		}

		tr.last-line {

			td {
				border-bottom: 1px solid $border-gray !important;
			}
		}

		div.table-part-line {
			background-color: $border-gray;
		}
		
		div.pikto-cow {
			background: url('../img/kisdaraboltmarha_dupla.svg') 0 -100px;
			background-clip: content-box;
			background-repeat: no-repeat;
			background-size: cover;
		}	
	}

	div.code {
		text-align: left;
		padding: 15px;

		div.frozen {
			width: 60px;
			height: 60px;
			float: right;
			background: url('../img/fagyasztott.svg') no-repeat;
		}

		.name-kg {
			display: inline-block;
		} 
	}

	span.enarcod {
		display: inline-block;
		margin: 15px 0 15px 0;
	}
	div.part-name {
		text-transform: uppercase;
		font-weight: bold;
		font-size: 18px;
	}
}

.card {
	width: 100%;
	font-size: 16px;

	span {
		border: none;
		font-weight: 100;
		padding-right: 10px;
	}

	td {
		text-align: center;
		width: 50%
	}

	td:nth-child(2) {
		border-left: 1px solid $background-gray;
		padding-top: -5px;
	}	

	tr:nth-child(2) td:first-child {
		text-transform: uppercase;
	}

	tr:first-child td:first-child  {
		font-weight: 50;
	}
}


.pagination {
	list-style-type: none;
 	display: block;
 	padding-top: 50px;
 	padding-bottom: 50px;
 	text-align: center;
} 

.pagination li {
	display: inline-block;
	padding-right: 20px;
}

.pagination a {
	color: $dark-silver;
	font-size: 16px;
	font-weight: bold;

	&:hover {
		color: $black;
	}

	&.active {
		color: $red;
	}

	&.disabled {
		color: $light-silver;
	}
}