.button {
	font-size: 14px;
	height: 35px;  
	color: $white;
	text-align: center;
	line-height: 36px;
	letter-spacing: 2px;
	margin: auto;
	cursor: pointer;
	padding: 0 10px 0 10px;
	border: 0;

	&.search {
		background-color: $red;
		text-transform: uppercase;
		display: inline-block;
		position: relative;
		bottom: -70px;

		&:hover {
			background-color: $dark-red;
		}
	}

	&.select {
		background-color: $red;
		font-size: 16px;
		text-transform: uppercase;
		margin: auto;
		margin-top: 32px;
		margin-bottom: 34px;
		display: inline-block;

		&:hover {
			background-color: $dark-red;
		}

		&.remove {
			color: $white;
			background-color: $gray;

			&:hover {
				background-color: $black;
			}
		}
	}

	&.next {
		background-color: $gray;
		text-transform: uppercase;
		display: inline-block;
		margin: 100px 0 100px 0;

		&:hover {
			background-color: $dark-gray;
		}
	}

	&.cart {
		background-color: $gray;
		display: inline-block;

		b {
			color: $white;
		}

		&:hover {
			background-color: $dark-gray;
		}
	}

	&.pay {
		background-color: $gray;
		text-transform: uppercase;
		display: inline-block;

		&:hover {
			background-color: $dark-gray;
		}
	}

	&.menu {
		height: 40px;
		width: 160px;
		text-align: right;
		font-size: 14px;
		margin: 0px 0 20px 0;
		background-color: $red;
		text-transform: uppercase;
		line-height: 40px;
		display: block;
		padding: 0;

		&:hover {
			background-color: $dark-red;
		}

		.fa-angle-right,
		.fa-angle-left {
			color: $white;
			font-size: 18px;
			padding: 0 10px 0 5px;
			vertical-align: sub;
		}

		.fa-angle-left {
			padding: 0 5px 0 10px;
		}

		&.long {
			width: 200px;
			margin: 0 -40px;
		}
	}

	&.disabled {
		background-color: $dark-silver;
		color: $white;
		cursor: default;
		pointer-events: none;
	}
}

.chooser {
	background-color: $light-gray;
	color: $gray;
	border: 2px solid $light-gray;
	margin-right: 10px;
	text-transform: uppercase;
	margin-bottom: 10px;
	font-size: 14px;
	height: 31px;
	line-height: 31px;
	display: inline-block;

	&:hover {
		color: $red;
	}

	&.active {
		color: $red;
		border: 2px solid $red;
	} 
}

.collapsible {
  background-color: $white;
  width: 100%;
  height: 77px;
  border: none;
  z-index: 1;
  position: relative;

  	&:focus {
  		outline: none;
  	}

 	.dot-mini {
		height: 132px;
		width: 152px;
		background-color: $white;
		border-radius: 50%;
		text-align: center;
		margin: auto;
		cursor: pointer;
		padding-top: 20px;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;

			&:hover {

				.fa-sort-down {
					color: $red;
				}
	
			}

		.filtermarha {
			background-image: url('../img/filtermarha.svg');
			background-repeat: no-repeat;
			background-size: 100%;
			background-position: center 0;
			vertical-align: top;
			background-clip: content-box;
			width: 100px;
			height: 80px;
			margin: auto;
		}

		.fa-sort-down {
			color: $silver;
			font-size: 26px;
		}
	}

	&.open {
		.dot-mini {
			bottom:-80px;
			height: 25px;
			width: 40px;
			padding-top: 15px;

			.filtermarha {
				display: none;
			}

			svg {
				transform: rotate(180deg);
			}
		}
	}
}