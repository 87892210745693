.beef-sheet {
	height: 520px;
	background: url(../img/pre-order.png) no-repeat center;
	background-size: cover;
	position: relative;

	.center-center {
		height: 230px;
	}

	table {
		width: 470px;
		background: $white;
		border: 10px solid $background-gray;

		tr td {
			height: 30px;
			border-bottom: 1px solid $background-gray;
			border-right: 1px solid $background-gray;
			border-top: 0;
			padding: 5px 20px;
			font-size: 16px;
			font-weight: 300;
			letter-spacing: 0;
			
			&:last-child {
				font-weight: 500; 
				border-right: 0;
			}
		}

		tr:last-child td {
			border-bottom: 0;
		}
	}
}

.data-sheet {
	margin-top: 90px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	border: 1px solid $font-gray;
	padding: 35px 0;
	margin-bottom: 70px;

	.data {
		padding: 0 25px;
		border-right: 1px solid $background-gray;
		font-size: 20px;
		font-weight: 500;
		font-weight: 500;

		h3 {
			margin: 0;
			padding: 0;
			font-size: 20px;
			font-weight: 700;
			color: $black;
			text-transform: uppercase;
			display: block;
			margin-bottom: 6px;

			a {
				text-transform: none;
				color: $red;
				display: inline-block;
				width: 24px;
				height: 24px;
				line-height: 24px;
				border: 1px solid $red;
				border-radius: 100%;
				text-align: center;
				font-weight: 700;
				margin-left: 5px;
				vertical-align: top;
			}
		}

		span {
			font-size: 12px;
			text-transform: uppercase;
			letter-spacing: 1px;
			display: block;
		}

		&:first-child {
			font-size: 14px;
			font-weight: 400;
		}

		.range {
			width: 100px;
		}
	}
}

.product-select {
	padding-bottom: 100px;
	.center {
		.right {
			position: absolute;
			right: 0px;
			font-size: 24px;
			font-weight: 500;

			a {
				margin: 0 0 0 30px;
				position: relative;
				z-index: 1;
			}
		}

		.number-input {
			display: block;

			button {
				font-size: 32px;
			}

			input[type=number] {
				vertical-align: top;
				height: 25px;
				padding: 8px 20px;
			}
		}

		div.placeholder::after {
			left: 100px;
			font-size: 14px;
		}

		.total {
			position: absolute;
			right: 200px;
			font-size: 24px;
			font-weight: 500;
			//margin-top: -5px;

			span {
				font-size: 12px;
				margin-bottom: 5px;
			}
		}
	}
}