
.form-check {
	display: block;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 14px;
	line-height: 28px;
	margin-bottom: 60px;

	input {
	  position: absolute;
	  opacity: 0;
	  cursor: pointer;
	  height: 0;
	  width: 0;
	}

	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 14px;
		width: 14px;
		border: 7px solid #eee;
		background-color: #eee;
	}

	&:hover input ~ .checkmark {
		background-color: #ccc;
	}

	input:checked ~ .checkmark {
		background-color: $red;
	}

	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}
	
	.form-check input:checked ~ .checkmark:after {
	 	display: block;
	}
	
	.form-check .checkmark:after {
		left: 9px;
		top: 5px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}
