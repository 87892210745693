.login {
	.btn-link {
		float: right;
		position: relative;
		z-index: 1;
		display: inline-block;
		height: 30px;
		line-height: 30px;
		padding-left: 30px;

		&:hover {
			color: $red;
		}
	}

	.button.next {
		width: 200px;
	}
}

#reg {
	position: absolute;
	z-index: 9;
	top: 0px;
	width: 60%;
	width: calc(100% + -440px);
	height: 100%;
	border-left: 10px solid $background-gray;
	background: $white;
	left: 80%;
	left: calc(100% - 210px);
	transition: 1s all;
}

#layer {
	position: absolute;
	z-index: 9;
	top: 0px;
	left: 0;
	width: 85%;
	transition: 1s all;
	height: 100%;
	border-right: 10px solid $background-gray;
	background: $white;

	.sub-layer {
		width: 100%;
		height: 100%;
		position: relative;
		overflow: hidden;
	}

	&.open {
		width: 13%;
		transition: 1s all;

		.button.nav {
			right: -10;
			width: 0;
			padding: 0;
			transition: 0.25s all;
			//transition-timing-function: ease-out;

			&.back {
				width: 140px;
				padding:0 15px;
				transition: 0.25s all;
				transition-delay: 0.7s;
				right: -170px;

				span {
					left: 0;
				}
			}

			span {
				right: 0;
				transition: 0.25s all;
			}
		}
	}

	.button.nav {
		position: absolute;
		right: -10px;
		top: 90px;
		width: 150px;
		background: $red;
		text-transform: uppercase;
		transition: 0.25s all;
		transition-delay: 0.7s;
		overflow: hidden;
		text-align: left;
		cursor: pointer;
		z-index: 999;
		color: $red;
		line-height: 35px;

		&:hover {
			background: $dark-red;
		}

		span {
			position: absolute;
			width: 140px;
			padding: 0 15px;
			transition: 0.25s all;
			text-align: right;
			color: $white;

			&:before {
				font-family: Fontawesome;
				position: absolute;
				font-size: 18px;
				color: $white;
			}
		}

		

		&.back {
			right: 0;
			width: 0;
			padding: 0;
			transition: 0.25s all;

			span {
				text-align: left;
				left: 0;

				&:before {
					content: '\f105';
					right: 12px;
				}
			}	
		}

		&.forward {
			text-align: right;

			span {
				right: 0;
			}
			
			span:before {
				content: '\f104';
				left: 12px;
			}		
		}
	}

	.login-container {
		position: absolute;
		left: 0;
		width: calc(100vw - 20px);
	}
}