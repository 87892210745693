div.filters {
  overflow: hidden;
}

div.parent {
	display: flex;
	justify-content: center;
	align-items: center;
}

div.quantity-kg {
	display: inline-block;
	float: right;
	margin-top: 70px;
}

div.placeholder::after {
	left: 47px;
}

div.hypen {
	width: 10px;
	height: 1px;
	background-color: $light-silver;
	display: inline-block;
}

div.finished-product {
	float: left;
	padding: 42px 60px 0 0;
}

div.beef-status {
	float: left;
	padding-right: 30px;
	display: inline-block;
}

div.cattle-type {
	float: right;
	display: inline-block;
}

div.meat-type {
	width: 755px;
	margin-top: 20px;
	margin-bottom: 20px;
}

div.quantity {
	width: 900px;
	margin: 80px 0 80px 20px;
	display: inline-block;

	&.pre-order {
		width: calc(100% - 110px);
		margin: 80px 110px 80px 0px;
	}

	span.chosen {
		float: right;
		margin-right: -110px;
		font-size: 28px;
		color: $red;
		font-weight: 600;
		line-height: 10px;
	}

	.vue-slider-rail {
		height: 1px;
		border-radius: 0px;
	}
	.vue-slider-process {
		background-color: $red;
		height: 3px !important;
		top: -1px !important;
	}
	.vue-slider-mark-step {
		border-radius: 0px;
		background-color: $light-silver;
		width: 1px;
		height: 10px;
	}

	.vue-slider-mark {
		top: -4px;
	}

	.vue-slider-mark {
		z-index: 0;
	}
	.vue-slider-dot {
		width: 10px !important;
		height: 10px !important;
	}

	.vue-slider-dot .vue-slider-dot-handle {
		background-color: $red;
		box-shadow: none;
		border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
		transform: rotate(45deg);
	}

	.vue-slider-dot:last-child .vue-slider-dot-handle {
		background-color: $red;
		box-shadow: none;
		border-radius: 80% 0 55% 50% / 55% 0 80% 50%;
		transform: rotate(-135deg);
	}

	.vue-slider-mark-label {
		color: $dark-silver;
		margin-top: 20px;
		font-size: 0px;

		&::after {
			content: " kg";
		}

		&:first-child {
			font-size: 14px !important;
		}
	}

	.vue-slider-dot-tooltip {
		display: none;
	}

	&.one .vue-slider-mark:nth-child(1n) .vue-slider-mark-label {
		font-size: 14px;
	}

	&.two .vue-slider-mark:nth-child(2n) .vue-slider-mark-label {
		font-size: 14px;
	}
	&.three .vue-slider-mark:nth-child(3n) .vue-slider-mark-label {
		font-size: 14px;
	}
	&.four .vue-slider-mark:nth-child(4n) .vue-slider-mark-label {
		font-size: 14px;
	}
	&.five .vue-slider-mark:nth-child(5n) .vue-slider-mark-label {
		font-size: 14px;
	}
	&.six .vue-slider-mark:nth-child(6n) .vue-slider-mark-label {
		font-size: 14px;
	}
}

.sum {
	font-size: 20px;
	font-weight: 600;

	a.button.next {
		margin-top: 10px;
	}
}

.cut-type {
	text-align: center;

	div {
		width: 45%;
		display: inline-block;
		text-align: left;
		margin: 2px;
		text-transform: uppercase;
		letter-spacing: 2px;

		.form-check {
			padding-left: 40px;
		}

		&.radio-left {
			text-align: right;

			.form-check {
				padding-left: 0;
				padding-right: 40px;

				.checkmark {
					left: auto;
					right: 0px;
				}
			}
		}

		:checked + span {
		    color: $red;
		}
	}
}

#beef_map {
	float: right;
	width: 320px;

	path {
		&:hover,
		.hover {
			fill: $light-black;
			cursor: pointer;
		}

		&.active{
			fill: $red;
		}

		&.disabled,
		&.disabled:hover {
			fill: $black;
			cursor: default;
		}
	}
}