@charset "UTF-8";

/* RESET ------------------------------------- */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: #333;
  outline: 0;
  font-weight: normal;
  text-decoration: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
}

input,
input:focus,
select,
select:focus,
textarea,
textarea:focus {
  outline: 0px !important;
  border: 0px;
  -webkit-appearance: none;
}

b {
  font-weight: bold;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

.clearfix {
  clear: both;
}

a:active {
  text-decoration: none;
}

body {
  background: #E7E7E7;
  padding: 8px 10px 10px;
  font-family: "Open Sans", sans-serif;
}

.wrap {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: auto;
}

.wrap.mini {
  max-width: 400px;
}

main {
  margin-bottom: 10px;
  min-height: calc(100vh - 300px);
  background: #FFFFFF;
  position: relative;
  overflow: hidden;
}

footer {
  height: 130px;
  background: #333333;
}

footer .center {
  text-align: center;
  color: #FFFFFF;
  opacity: 0.7;
  line-height: 130px;
  font-size: 12px;
}

footer .center b {
  color: #FFFFFF;
}

footer .right {
  position: absolute;
  right: 0px;
  line-height: 130px;
  font-size: 10px;
  color: #FFFFFF;
  opacity: 0.5;
  text-transform: uppercase;
  padding-right: 70px;
  z-index: 1;
}

footer .right a.dyb {
  display: inline-block;
  width: 68px;
  height: 52px;
  background: url(./../img/dyb.svg) no-repeat center;
  background-size: contain;
  opacity: 0.6;
  vertical-align: middle;
  margin-left: 25px;
  margin-top: -2px;
}

footer .right a.dyb:hover {
  opacity: 1;
}

.center {
  text-align: center;
}

.center-center {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.button {
  font-size: 14px;
  height: 35px;
  color: #FFFFFF;
  text-align: center;
  line-height: 36px;
  letter-spacing: 2px;
  margin: auto;
  cursor: pointer;
  padding: 0 10px 0 10px;
  border: 0;
}

.button.search {
  background-color: #DC4228;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
  bottom: -70px;
}

.button.search:hover {
  background-color: #C41C00;
}

.button.select {
  background-color: #DC4228;
  font-size: 16px;
  text-transform: uppercase;
  margin: auto;
  margin-top: 32px;
  margin-bottom: 34px;
  display: inline-block;
}

.button.select:hover {
  background-color: #C41C00;
}

.button.select.remove {
  color: #FFFFFF;
  background-color: #333333;
}

.button.select.remove:hover {
  background-color: #000000;
}

.button.next {
  background-color: #333333;
  text-transform: uppercase;
  display: inline-block;
  margin: 100px 0 100px 0;
}

.button.next:hover {
  background-color: #000000;
}

.button.cart {
  background-color: #333333;
  display: inline-block;
}

.button.cart b {
  color: #FFFFFF;
}

.button.cart:hover {
  background-color: #000000;
}

.button.pay {
  background-color: #333333;
  text-transform: uppercase;
  display: inline-block;
}

.button.pay:hover {
  background-color: #000000;
}

.button.menu {
  height: 40px;
  width: 160px;
  text-align: right;
  font-size: 14px;
  margin: 0px 0 20px 0;
  background-color: #DC4228;
  text-transform: uppercase;
  line-height: 40px;
  display: block;
  padding: 0;
}

.button.menu:hover {
  background-color: #C41C00;
}

.button.menu .fa-angle-right,
.button.menu .fa-angle-left {
  color: #FFFFFF;
  font-size: 18px;
  padding: 0 10px 0 5px;
  vertical-align: sub;
}

.button.menu .fa-angle-left {
  padding: 0 5px 0 10px;
}

.button.menu.long {
  width: 200px;
  margin: 0 -40px;
}

.button.disabled {
  background-color: #888888;
  color: #FFFFFF;
  cursor: default;
  pointer-events: none;
}

.chooser {
  background-color: #EFEFEF;
  color: #333333;
  border: 2px solid #EFEFEF;
  margin-right: 10px;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 14px;
  height: 31px;
  line-height: 31px;
  display: inline-block;
}

.chooser:hover {
  color: #DC4228;
}

.chooser.active {
  color: #DC4228;
  border: 2px solid #DC4228;
}

.collapsible {
  background-color: #FFFFFF;
  width: 100%;
  height: 77px;
  border: none;
  z-index: 1;
  position: relative;
}

.collapsible:focus {
  outline: none;
}

.collapsible .dot-mini {
  height: 132px;
  width: 152px;
  background-color: #FFFFFF;
  border-radius: 50%;
  text-align: center;
  margin: auto;
  cursor: pointer;
  padding-top: 20px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.collapsible .dot-mini:hover .fa-sort-down {
  color: #DC4228;
}

.collapsible .dot-mini .filtermarha {
  background-image: url("./../img/filtermarha.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;
  vertical-align: top;
  background-clip: content-box;
  width: 100px;
  height: 80px;
  margin: auto;
}

.collapsible .dot-mini .fa-sort-down {
  color: #A3A3A3;
  font-size: 26px;
}

.collapsible.open .dot-mini {
  bottom: -80px;
  height: 25px;
  width: 40px;
  padding-top: 15px;
}

.collapsible.open .dot-mini .filtermarha {
  display: none;
}

.collapsible.open .dot-mini svg {
  transform: rotate(180deg);
}

div.dot {
  height: 98px;
  width: 98px;
  background-color: #EFEFEF;
  border-radius: 50%;
  border: 2px #EFEFEF solid;
  display: inline-block;
  margin-right: 25px;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center 0;
  vertical-align: top;
  text-align: center;
}

div.dot:hover {
  background-position: center -98px;
  cursor: pointer;
}

div.dot.finished {
  background-image: url("./../img/frozen.svg");
}

div.dot.dry-age {
  background-image: url("./../img/szarazerlelt.svg");
}

div.dot.wet-age {
  background-image: url("./../img/nedveserlelt.svg");
}

div.dot.frozen {
  background-image: url("./../img/frozen.svg");
}

div.dot.blonde {
  background-image: url("./../img/blonde.svg");
}

div.dot.limousin {
  background-image: url("./../img/limo.svg");
}

div.dot.magyar {
  background-image: url("./../img/szurke.svg");
}

div.dot.active {
  border: 2px #DC4228 solid;
  background-position: center -98px;
}

div.dot.active:hover {
  border: 2px #DC4228 solid;
}

input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.number-input {
  border: none;
  display: inline-flex;
}

.number-input button {
  outline: none;
  -webkit-appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0;
  font-size: 24px;
  color: #DC4228;
  font-weight: 900;
}

.number-input button:hover {
  color: #C41C00;
}

/*
.number-input button:after {
	font-family: "Font Awesome 5 Free"; 
	content: "\f056";
}

.number-input button.plus:after {
	font-family: "Font Awesome 5 Free";  
	content: "\f055";
}*/

.number-input input[type=number] {
  font-size: 14px;
  color: #DC4228;
  width: 47px;
  height: 12px;
  border: 1px solid #CCCCCC;
  padding: 8px;
  margin: 3px;
}

.placeholder {
  position: relative;
}

.placeholder::after {
  font-size: 10px;
  color: #888888;
  position: absolute;
  right: 47px;
  top: 12px;
  content: attr(data-placeholder);
  pointer-events: none;
}

input[type=text],
input[type=email],
input[type=password],
textarea {
  width: 100%;
  max-width: 400px;
  height: 35px;
  line-height: 35px;
  border: 1px solid #A3A3A3;
  padding: 0 10px;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus,
textarea:focus {
  border-color: #000000;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

textarea {
  height: 105px;
  max-height: 105px;
  margin-bottom: 100px;
  padding: 10px;
  line-height: 20px;
  font-family: "Open Sans", sans-serif;
}

textarea:focus {
  border: 1px solid #000000;
}

label.text-label {
  position: absolute;
  right: calc(100% + 20px);
  line-height: 35px;
  width: 300px;
  text-align: right;
  font-size: 14px;
  text-transform: uppercase;
  color: #888888;
  letter-spacing: 1px;
}

.form-check {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 60px;
}

.form-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.form-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  border: 7px solid #eee;
  background-color: #eee;
}

.form-check:hover input ~ .checkmark {
  background-color: #ccc;
}

.form-check input:checked ~ .checkmark {
  background-color: #DC4228;
}

.form-check .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-check .form-check input:checked ~ .checkmark:after {
  display: block;
}

.form-check .form-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

div.popup {
  height: 650px;
  width: 940px;
  background-color: #FFFFFF;
  border: 10px #E7E7E7 solid;
  position: relative;
}

div.popup .close {
  cursor: pointer;
}

div.popup .close .x {
  height: 20px;
  width: 2px;
  margin: 20px 30px 0 0;
  background-color: #A3A3A3;
  transform: rotate(45deg);
  float: right;
}

div.popup .close .xx {
  height: 20px;
  width: 2px;
  background-color: #A3A3A3;
  transform: rotate(90deg);
}

div.popup .close:hover .x,
div.popup .close:hover .xx {
  background-color: #000000;
}

div.popup div.popup-cow {
  width: 623px;
  height: 512px;
  position: relative;
  bottom: -10px;
  margin: auto;
}

div.popup div.popup-cow.parted-cow {
  background: url("./../img/marha_magyar_bontas.svg");
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
}

table {
  font-size: 14px;
  letter-spacing: 1px;
  margin: auto;
}

th,
td {
  padding: 5px;
  text-align: right;
}

th {
  font-size: 12px;
  height: 35px;
  font-weight: 100;
  text-transform: uppercase;
}

td {
  border-top: 1.5px solid #E7E7E7;
  vertical-align: middle;
  height: 40px;
}

td:nth-child(1) {
  border-top: 1.5px solid #FFFFFF;
}

td:nth-child(2) {
  width: 200px;
  text-align: left;
}

td:nth-child(3) {
  width: 200px;
  text-align: center;
}

th:nth-child(3) {
  text-align: center;
}

td:nth-child(4) {
  width: 190px;
  text-align: right;
}

.last-row td {
  background-color: #F7F7F7;
  border-top: 23px solid #FFFFFF;
  height: 47px;
  font-weight: 100;
  font-size: 12px;
  padding-left: 0;
  padding-right: 14px;
  margin-top: 20px;
  text-transform: uppercase;
}

.last-row td:nth-child(4) {
  padding-right: 5px;
}

.last-row b {
  font-size: 16px;
}

.info {
  font-size: 16px;
}

.info td {
  height: 30px;
  padding: 5px;
  line-height: 2;
  border-top: 1px solid #E7E7E7;
  margin: auto;
  letter-spacing: 0;
}

.info span {
  border-left: 1px solid #E7E7E7;
  font-weight: 600;
  padding: 5px 0 5px 10px;
}

.info td:nth-child(1) {
  font-weight: 100;
}

.info tr.last-line {
  height: 49px;
}

.info tr.last-line td {
  height: 49px;
  box-sizing: border-box;
  border-bottom: 1px solid #E7E7E7;
}

.table-part-line {
  height: 30px;
  width: 1px;
  background-color: #E7E7E7;
}

.cards {
  margin-top: -21px;
  padding-top: 100px;
  position: relative;
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.card-cow {
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 100;
  background-color: #FFFFFF;
  display: inline-block;
  padding-bottom: 0;
  text-align: center;
}

.card-cow div.pikto-cow {
  width: 110px;
  height: 92px;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 27px;
  background: url("./../img/kisdaraboltmarha_dupla.svg") 0 0;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-cow.selected {
  background-color: #A6A6A6;
}

.card-cow.selected td:nth-child(2) {
  border-left: 1px solid #979797;
}

.card-cow.selected td {
  border-top: 1px solid #979797 !important;
}

.card-cow.selected tr.last-line td {
  border-bottom: 1px solid #979797 !important;
}

.card-cow.selected div.table-part-line {
  background-color: #979797;
}

.card-cow.selected div.pikto-cow {
  background: url("./../img/kisdaraboltmarha_dupla.svg") 0 -100px;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.card-cow div.code {
  text-align: left;
  padding: 15px;
}

.card-cow div.code div.frozen {
  width: 60px;
  height: 60px;
  float: right;
  background: url("./../img/fagyasztott.svg") no-repeat;
}

.card-cow div.code .name-kg {
  display: inline-block;
}

.card-cow span.enarcod {
  display: inline-block;
  margin: 15px 0 15px 0;
}

.card-cow div.part-name {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 18px;
}

.card {
  width: 100%;
  font-size: 16px;
}

.card span {
  border: none;
  font-weight: 100;
  padding-right: 10px;
}

.card td {
  text-align: center;
  width: 50%;
}

.card td:nth-child(2) {
  border-left: 1px solid #E7E7E7;
  padding-top: -5px;
}

.card tr:nth-child(2) td:first-child {
  text-transform: uppercase;
}

.card tr:first-child td:first-child {
  font-weight: 50;
}

.pagination {
  list-style-type: none;
  display: block;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.pagination li {
  display: inline-block;
  padding-right: 20px;
}

.pagination a {
  color: #888888;
  font-size: 16px;
  font-weight: bold;
}

.pagination a:hover {
  color: #000000;
}

.pagination a.active {
  color: #DC4228;
}

.pagination a.disabled {
  color: #CCCCCC;
}

div.pre {
  height: 400px;
  position: relative;
}

div.gray-knife {
  background-color: #E7E7E7;
  width: 100%;
  min-height: 400px;
}

div.knife-description {
  padding: 60px;
  margin: auto;
  width: 800px;
}

div.knife-description p.number-knife-one {
  display: inline-block;
  position: absolute;
  font-size: 30px;
  font-weight: 900;
}

div.knife-description p.text-knife {
  width: 170px;
  padding: 0 50px 0 38px;
  font-size: 12px;
  display: inline-block;
  text-align: top;
  float: left;
}

.timeline {
  position: relative;
  padding-top: 140px;
}

.month-bar {
  width: 1080px;
  padding: 0 0 60px 0;
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
}

.month-bar .month {
  height: 28px;
  text-align: center;
  line-height: 25px;
  background-color: #D8D8D8;
  border-bottom: 3px solid #FFFFFF;
  position: relative;
  width: 100%;
  border-left: 2px solid #CCCCCC;
}

.month-bar .month span {
  text-transform: uppercase;
  font-size: 12px;
  color: #000000;
  font-weight: 100;
  letter-spacing: 1px;
}

.month-bar .month:first-child {
  border-left: 0;
}

.select div.knife {
  background: url("./../img/kes.svg") 0 -67px;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.select div.cut-cow {
  background: url("./../img/szurkemarha_vagni.svg") 0 -36px;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.cutting {
  position: absolute;
  display: block;
  top: 165px;
  margin-left: 20px;
}

.cutting:hover div.knife {
  background: url("./../img/kes.svg") 0 -67px;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  display: block;
}

.cutting:hover div.cut-card {
  display: block;
  margin-left: -60px;
  position: absolute;
}

.cutting:hover div.arrow-up {
  display: block;
}

.knife {
  height: 67px;
  width: 20px;
  display: block;
  background: url("./../img/kes.svg") 0 0;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 80px;
  left: -1px;
}

.mark {
  height: 9px;
  width: 2px;
  background-color: #A3A3A3;
  margin: 0 0 0 8px;
  display: block;
  position: absolute;
}

.cut-cow {
  height: 32px;
  width: 39px;
  display: block;
  background: url("./../img/szurkemarha_vagni.svg") 0 0;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  margin-top: 13px;
  left: -12px;
}

.white-bar {
  height: 3px;
  width: 50px;
  background-color: #FFFFFF;
  margin: 50px 0 0 -17px;
}

.white-bar .red-bar {
  height: 100%;
  width: 50%;
  background-color: #DC4228;
}

.hover-cut-card {
  margin-top: -78px;
  display: block;
  position: absolute;
  z-index: 1;
}

.hover-cut-card .arrow-up {
  display: none;
  margin: auto;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #FFFFFF;
  z-index: 1;
  position: relative;
}

.hover-cut-card .cut-card {
  font-size: 12px;
  color: #000000;
  text-align: center;
  height: 195px;
  width: 146px;
  background-color: #FFFFFF;
  display: none;
  filter: drop-shadow(0px 3px 6px rgba(60, 50, 0, 0.2));
}

.hover-cut-card .cut-card .white-bar {
  width: 100px;
  background-color: #F0F0F0;
  margin: auto;
  margin-bottom: 3px;
}

.hover-cut-card .cut-card .cut-card-title {
  text-transform: uppercase;
  padding: 8px 8px 2px 8px;
  font-weight: 100;
}

.hover-cut-card .cut-card .cut-card-cow {
  height: 50px;
  width: 60px;
  margin: auto;
  margin-bottom: 8px;
  background: url("./../img/szurkemarha_vagni.svg") 0 -57px;
  background-clip: content-box;
  background-repeat: no-repeat;
  background-size: cover;
}

.hover-cut-card .cut-card .date {
  font-size: 16px;
  font-weight: bold;
}

.hover-cut-card .cut-card .enar {
  font-size: 10px;
  margin: 3px 0 -11px 0;
}

.hover-cut-card .cut-card .hu-number {
  font-size: 12px;
  text-transform: uppercase;
}

.two {
  margin: 0 100px 0 100px;
}

.three {
  margin: 0 0 0 50px;
}

div.pink {
  background-color: pink;
  width: 100%;
}

div.gray {
  background-color: #E7E7E7;
  width: 100%;
  padding-top: 100px;
}

div.white-line {
  background-color: #FFFFFF;
  width: 100%;
  height: 20px;
  border-top: 10px solid #E7E7E7;
}

div.white {
  background-color: #FFFFFF;
  width: 100%;
  padding-top: 40px;
}

h1 {
  font-size: 26px;
  text-transform: uppercase;
  text-align: left;
  font-weight: bold;
  padding-bottom: 50px;
}

h2 {
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  color: #888888;
  font-weight: 100;
  padding: 0 0 25px 0;
}

h3 {
  font-size: 12px;
  color: #888888;
  display: inline-block;
  margin-top: 110px;
}

h4 {
  font-size: 20px;
  color: #000000;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 50px;
  padding-bottom: 50px;
}

h5 {
  font-size: 12px;
  text-transform: uppercase;
  text-align: left;
  color: #888888;
  font-weight: 100;
  padding: 0 0 25px 0;
  padding-left: 64px;
}

p.text {
  width: 500px;
  text-align: left;
  display: inline-block;
  float: left;
  word-break: break-all;
  padding: 0;
}

div.footer {
  width: 100%;
  height: 130px;
  background-color: #333333;
  position: absolute;
  display: block;
  text-align: center;
}

div.footer div.copyright {
  position: relative;
  top: 50%;
  margin: auto;
}

div.footer div.copyright p {
  color: #C2C2C2;
  font-size: 12px;
}

div.footer div.copyright p b {
  color: #C2C2C2;
}

div.footer div.text-logo {
  position: absolute;
  right: 60px;
  top: 40px;
}

div.footer div.text-logo div.design {
  font-size: 10px;
  color: #868686;
  text-transform: uppercase;
  display: inline-block;
  padding-right: 20px;
  vertical-align: top;
  padding-top: 20px;
}

div.footer div.text-logo div.logo {
  width: 69px;
  height: 52px;
  background: url("./../img/dyb.svg");
  display: inline-block;
}

div.filters {
  overflow: hidden;
}

div.parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.quantity-kg {
  display: inline-block;
  float: right;
  margin-top: 70px;
}

div.placeholder::after {
  left: 47px;
}

div.hypen {
  width: 10px;
  height: 1px;
  background-color: #CCCCCC;
  display: inline-block;
}

div.finished-product {
  float: left;
  padding: 42px 60px 0 0;
}

div.beef-status {
  float: left;
  padding-right: 30px;
  display: inline-block;
}

div.cattle-type {
  float: right;
  display: inline-block;
}

div.meat-type {
  width: 755px;
  margin-top: 20px;
  margin-bottom: 20px;
}

div.quantity {
  width: 900px;
  margin: 80px 0 80px 20px;
  display: inline-block;
}

div.quantity.pre-order {
  width: calc(100% - 110px);
  margin: 80px 110px 80px 0px;
}

div.quantity span.chosen {
  float: right;
  margin-right: -110px;
  font-size: 28px;
  color: #DC4228;
  font-weight: 600;
  line-height: 10px;
}

div.quantity .vue-slider-rail {
  height: 1px;
  border-radius: 0px;
}

div.quantity .vue-slider-process {
  background-color: #DC4228;
  height: 3px !important;
  top: -1px !important;
}

div.quantity .vue-slider-mark-step {
  border-radius: 0px;
  background-color: #CCCCCC;
  width: 1px;
  height: 10px;
}

div.quantity .vue-slider-mark {
  top: -4px;
}

div.quantity .vue-slider-mark {
  z-index: 0;
}

div.quantity .vue-slider-dot {
  width: 10px !important;
  height: 10px !important;
}

div.quantity .vue-slider-dot .vue-slider-dot-handle {
  background-color: #DC4228;
  box-shadow: none;
  border-radius: 80% 0 55% 50%/55% 0 80% 50%;
  transform: rotate(45deg);
}

div.quantity .vue-slider-dot:last-child .vue-slider-dot-handle {
  background-color: #DC4228;
  box-shadow: none;
  border-radius: 80% 0 55% 50%/55% 0 80% 50%;
  transform: rotate(-135deg);
}

div.quantity .vue-slider-mark-label {
  color: #888888;
  margin-top: 20px;
  font-size: 0px;
}

div.quantity .vue-slider-mark-label::after {
  content: " kg";
}

div.quantity .vue-slider-mark-label:first-child {
  font-size: 14px !important;
}

div.quantity .vue-slider-dot-tooltip {
  display: none;
}

div.quantity.one .vue-slider-mark:nth-child(1n) .vue-slider-mark-label {
  font-size: 14px;
}

div.quantity.two .vue-slider-mark:nth-child(2n) .vue-slider-mark-label {
  font-size: 14px;
}

div.quantity.three .vue-slider-mark:nth-child(3n) .vue-slider-mark-label {
  font-size: 14px;
}

div.quantity.four .vue-slider-mark:nth-child(4n) .vue-slider-mark-label {
  font-size: 14px;
}

div.quantity.five .vue-slider-mark:nth-child(5n) .vue-slider-mark-label {
  font-size: 14px;
}

div.quantity.six .vue-slider-mark:nth-child(6n) .vue-slider-mark-label {
  font-size: 14px;
}

.sum {
  font-size: 20px;
  font-weight: 600;
}

.sum a.button.next {
  margin-top: 10px;
}

.cut-type {
  text-align: center;
}

.cut-type div {
  width: 45%;
  display: inline-block;
  text-align: left;
  margin: 2px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.cut-type div .form-check {
  padding-left: 40px;
}

.cut-type div.radio-left {
  text-align: right;
}

.cut-type div.radio-left .form-check {
  padding-left: 0;
  padding-right: 40px;
}

.cut-type div.radio-left .form-check .checkmark {
  left: auto;
  right: 0px;
}

.cut-type div :checked + span {
  color: #DC4228;
}

#beef_map {
  float: right;
  width: 320px;
}

#beef_map path:hover,
#beef_map path .hover {
  fill: #454545;
  cursor: pointer;
}

#beef_map path.active {
  fill: #DC4228;
}

#beef_map path.disabled,
#beef_map path.disabled:hover {
  fill: #000000;
  cursor: default;
}

.cart-contents {
  width: 100%;
  height: 440px;
  background-color: #FFFFFF;
}

.cart-contents h4 {
  text-transform: uppercase;
  padding-top: 70px;
}

.eye {
  border-radius: 100%;
  position: relative;
  width: 30px;
  height: 30px;
  background-color: #EFEFEF;
  display: inline-block;
}

.eye:hover {
  background-color: #E7E7E7;
}

.bogar {
  border-radius: 100%;
  position: relative;
  width: 13px;
  height: 13px;
  background-color: red;
  margin: auto;
  margin-top: 7px;
}

.bontas {
  margin-left: 50%;
}

.left {
  left: 100px;
}

.container {
  text-transform: uppercase;
  font-size: 14px !important;
  padding-top: 6px;
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  font-size: 14px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #EFEFEF;
  border-radius: 50%;
}

.container:hover input ~ .checkmark {
  background-color: #E7E7E7;
}

.container input:checked ~ .checkmark {
  background-color: #EFEFEF;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
  background-color: red;
  height: 14px;
  width: 14px;
}

.container .checkmark:after {
  top: 8px;
  left: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
  color: red;
}

.left {
  margin-left: 50px;
}

.range {
  height: 3px;
  background: #D8D8D8;
  display: inline-block;
  width: calc(100% - 65px);
  vertical-align: middle;
  margin-right: 6px;
}

.range .avaliable {
  height: 100%;
  width: 100%;
  background: #DC4228;
}

.navbar {
  height: 130px;
  background: #FFFFFF;
  margin-bottom: 10px;
}

.navbar a.navbar-brand {
  display: block;
  position: absolute;
  left: 100px;
  top: -24px;
  background: url("./../img/logo.svg") no-repeat left top;
  background-size: contain;
  width: 250px;
  height: 120px;
}

.navbar ul.lang-menu {
  float: right;
  list-style: none;
  padding-top: 50px;
  margin-right: 40px;
  word-spacing: -4px;
}

.navbar ul.lang-menu li {
  display: inline-block;
  border-right: 1px solid #CCCCCC;
  width: 38px;
  text-align: center;
  height: 18px;
  line-height: 18px;
}

.navbar ul.lang-menu li:last-child {
  border: 0;
}

.navbar ul.lang-menu li a {
  font-weight: 300;
}

.navbar ul.lang-menu li a.active {
  font-weight: 700;
  color: #DC4228;
}

.navbar ul.main-menu {
  list-style: none;
  text-align: center;
  padding-top: 50px;
}

.navbar ul.main-menu li {
  display: inline-block;
  padding: 0 35px;
}

.navbar ul.main-menu li a {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  line-height: 26px;
}

.navbar ul.main-menu li a.active,
.navbar ul.main-menu li a:hover {
  color: #DC4228;
}

.navbar ul.customer-menu {
  float: right;
  list-style: none;
  margin-top: 50px;
}

.navbar ul.customer-menu li a {
  color: #888888;
}

table.cart {
  width: 100%;
  max-width: 1180px;
  margin: auto;
  border-spacing: 14px;
  table-layout: fixed;
  text-align: center;
  margin-top: 50px;
}

table.cart th {
  text-align: center;
}

table.cart th:nth-child(4) {
  width: 30px;
}

table.cart td {
  border: 0;
  border-right: 1px solid #E7E7E7;
  text-align: center;
  height: 30px;
  padding: 0;
  font-size: 18px;
  letter-spacing: 0;
}

table.cart td:nth-child(3)::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: #E7E7E7;
  display: block;
  margin-top: -10px;
}

table.cart td:nth-child(4) {
  width: 30px;
  border-right: 1px solid #E7E7E7;
}

table.cart td:nth-child(4) svg {
  position: absolute;
  cursor: pointer;
  margin: -8px 0 0 -14px;
}

table.cart td:nth-child(4) svg:hover {
  color: #DC4228;
}

table.cart td:first-child,
table.cart td:nth-child(2) {
  color: #A3A3A3;
}

table.cart td:first-child {
  border-left: 1px solid #E7E7E7;
  text-transform: uppercase;
}

table.cart td:first-child span {
  text-transform: none;
  color: #A3A3A3;
  font-size: 16px;
  font-weight: 300;
}

table.cart tr:last-child td {
  border: 0;
  font-weight: 800;
}

.mini-cart {
  position: fixed;
  right: -94px;
  top: calc(50% - 50px);
  background: #333333;
  width: 94px;
  height: 80px;
  z-index: 999;
  cursor: pointer;
  color: white;
  text-align: center;
  font-size: 30px;
  padding-top: 20px;
  transition: 0.2s all;
}

.mini-cart p {
  color: #FFFFFF;
  font-size: 14px;
}

.mini-cart p b {
  color: #FFFFFF;
}

.mini-cart.close {
  right: -94px;
  transition: 0.5s all;
}

.mini-cart.open {
  right: 0px;
  transition: 0.5s all;
}

.login .btn-link {
  float: right;
  position: relative;
  z-index: 1;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding-left: 30px;
}

.login .btn-link:hover {
  color: #DC4228;
}

.login .button.next {
  width: 200px;
}

#reg {
  position: absolute;
  z-index: 9;
  top: 0px;
  width: 60%;
  width: calc(100% + -440px);
  height: 100%;
  border-left: 10px solid #E7E7E7;
  background: #FFFFFF;
  left: 80%;
  left: calc(100% - 210px);
  transition: 1s all;
}

#layer {
  position: absolute;
  z-index: 9;
  top: 0px;
  left: 0;
  width: 85%;
  transition: 1s all;
  height: 100%;
  border-right: 10px solid #E7E7E7;
  background: #FFFFFF;
}

#layer .sub-layer {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#layer.open {
  width: 13%;
  transition: 1s all;
}

#layer.open .button.nav {
  right: -10;
  width: 0;
  padding: 0;
  transition: 0.25s all;
}

#layer.open .button.nav.back {
  width: 140px;
  padding: 0 15px;
  transition: 0.25s all;
  transition-delay: 0.7s;
  right: -170px;
}

#layer.open .button.nav.back span {
  left: 0;
}

#layer.open .button.nav span {
  right: 0;
  transition: 0.25s all;
}

#layer .button.nav {
  position: absolute;
  right: -10px;
  top: 90px;
  width: 150px;
  background: #DC4228;
  text-transform: uppercase;
  transition: 0.25s all;
  transition-delay: 0.7s;
  overflow: hidden;
  text-align: left;
  cursor: pointer;
  z-index: 999;
  color: #DC4228;
  line-height: 35px;
}

#layer .button.nav:hover {
  background: #C41C00;
}

#layer .button.nav span {
  position: absolute;
  width: 140px;
  padding: 0 15px;
  transition: 0.25s all;
  text-align: right;
  color: #FFFFFF;
}

#layer .button.nav span:before {
  font-family: Fontawesome;
  position: absolute;
  font-size: 18px;
  color: #FFFFFF;
}

#layer .button.nav.back {
  right: 0;
  width: 0;
  padding: 0;
  transition: 0.25s all;
}

#layer .button.nav.back span {
  text-align: left;
  left: 0;
}

#layer .button.nav.back span:before {
  content: "";
  right: 12px;
}

#layer .button.nav.forward {
  text-align: right;
}

#layer .button.nav.forward span {
  right: 0;
}

#layer .button.nav.forward span:before {
  content: "";
  left: 12px;
}

#layer .login-container {
  position: absolute;
  left: 0;
  width: calc(100vw - 20px);
}

.checkout h2 {
  text-align: center;
}

.checkout form,
.checkout .form-group {
  max-width: 400px;
  margin: auto;
  position: relative;
}

.checkout form.shipping-address {
  margin-bottom: 100px;
}

.checkout .mode,
.checkout .payment {
  margin-bottom: 100px;
  display: grid;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  grid-template-columns: 1fr 1fr;
}

.checkout .mode > div,
.checkout .payment > div {
  padding: 50px;
  border: 1px solid #E7E7E7;
  text-align: center;
  cursor: pointer;
  margin-right: -2px;
  background: white;
}

.checkout .mode > div:hover,
.checkout .mode > div.active,
.checkout .mode > div.active:hover,
.checkout .payment > div:hover,
.checkout .payment > div.active,
.checkout .payment > div.active:hover {
  border: 2px solid #DC4228;
  padding: 49px;
  position: relative;
  z-index: 99;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
}

.checkout .mode > div:hover .pikto,
.checkout .mode > div.active .pikto,
.checkout .mode > div.active:hover .pikto,
.checkout .payment > div:hover .pikto,
.checkout .payment > div.active .pikto,
.checkout .payment > div.active:hover .pikto {
  filter: saturate(1);
  opacity: 1;
}

.checkout .mode > div:hover,
.checkout .payment > div:hover {
  border-color: #E7E7E7;
  z-index: 9;
}

.checkout .mode > div .pikto,
.checkout .payment > div .pikto {
  height: 90px;
  background: url(./../img/delivery.svg) no-repeat center;
  background-size: contain;
  margin-bottom: 20px;
  filter: saturate(0);
  opacity: 0.7;
}

.checkout .mode > div strong,
.checkout .payment > div strong {
  display: block;
  padding: 30px 0 15px;
  font-weight: 700;
}

.checkout .mode > div p,
.checkout .payment > div p {
  text-align: left;
  font-size: 12px;
}

.checkout .payment {
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}

.checkout .payment:before,
.checkout .payment:after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 1px;
  background: white;
  height: 20px;
  z-index: 1;
}

.checkout .payment:after {
  top: auto;
  bottom: 1px;
}

.checkout .payment .soon {
  position: relative;
  pointer-events: none;
}

.checkout .payment .soon .pikto {
  opacity: 0.2;
}

.checkout .payment .soon:after {
  content: "HAMAROSAN";
  position: absolute;
  transform: rotate(-30deg);
  left: 0;
  right: 0;
  margin: auto;
  top: 80px;
  font-size: 27px;
}

.checkout .payment .soon:hover {
  padding: 50px;
  border: 1px solid #E7E7E7;
  z-index: 0;
}

.checkout .payment .soon:hover .pikto {
  opacity: 0.2;
  filter: saturate(0);
}

.checkout .autocomplete-results {
  position: absolute;
  background: #FFFFFF;
  z-index: 99;
  list-style: none;
  width: 100%;
  max-width: 400px;
  margin-top: -20px;
}

.checkout .autocomplete-results li {
  background-color: #eee;
  cursor: pointer;
  padding: 0px 15px;
  font-size: 14px;
}

.checkout .autocomplete-results li:hover {
  background-color: #ddd;
}

.checkout .autocomplete-results li:first-child {
  padding-top: 5px;
}

.checkout .autocomplete-results li:last-child {
  padding-bottom: 5px;
}

.thanks,
.contact {
  background: url(./../img/thankyou.jpg) no-repeat center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
}

.thanks .center-center,
.contact .center-center {
  width: 350px;
  height: 250px;
  color: #FFFFFF;
}

.thanks .center-center p,
.contact .center-center p {
  color: #FFFFFF;
}

.thanks .center-center h1,
.contact .center-center h1 {
  color: #FFFFFF;
  text-align: center;
}

.thanks .center-center .button,
.contact .center-center .button {
  background: #FFFFFF;
  margin-top: 40px;
  color: #000000;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 25px;
}

.contact {
  background-image: url(./../img/contact.jpg);
}

.contact .center-center {
  background: #FFFFFF;
  color: #000000;
  text-align: left;
  padding: 30px;
  line-height: 30px;
  width: 300px;
  height: 210px;
}

.contact .center-center h1 {
  color: #000000;
  text-align: left;
  font-size: 20px;
  letter-spacing: 1px;
  padding-bottom: 30px;
}

.contact .center-center span {
  font-style: italic;
}

.beef-sheet {
  height: 520px;
  background: url(./../img/pre-order.png) no-repeat center;
  background-size: cover;
  position: relative;
}

.beef-sheet .center-center {
  height: 230px;
}

.beef-sheet table {
  width: 470px;
  background: #FFFFFF;
  border: 10px solid #E7E7E7;
}

.beef-sheet table tr td {
  height: 30px;
  border-bottom: 1px solid #E7E7E7;
  border-right: 1px solid #E7E7E7;
  border-top: 0;
  padding: 5px 20px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
}

.beef-sheet table tr td:last-child {
  font-weight: 500;
  border-right: 0;
}

.beef-sheet table tr:last-child td {
  border-bottom: 0;
}

.data-sheet {
  margin-top: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  border: 1px solid #868686;
  padding: 35px 0;
  margin-bottom: 70px;
}

.data-sheet .data {
  padding: 0 25px;
  border-right: 1px solid #E7E7E7;
  font-size: 20px;
  font-weight: 500;
  font-weight: 500;
}

.data-sheet .data h3 {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  color: #000000;
  text-transform: uppercase;
  display: block;
  margin-bottom: 6px;
}

.data-sheet .data h3 a {
  text-transform: none;
  color: #DC4228;
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border: 1px solid #DC4228;
  border-radius: 100%;
  text-align: center;
  font-weight: 700;
  margin-left: 5px;
  vertical-align: top;
}

.data-sheet .data span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: block;
}

.data-sheet .data:first-child {
  font-size: 14px;
  font-weight: 400;
}

.data-sheet .data .range {
  width: 100px;
}

.product-select {
  padding-bottom: 100px;
}

.product-select .center .right {
  position: absolute;
  right: 0px;
  font-size: 24px;
  font-weight: 500;
}

.product-select .center .right a {
  margin: 0 0 0 30px;
  position: relative;
  z-index: 1;
}

.product-select .center .number-input {
  display: block;
}

.product-select .center .number-input button {
  font-size: 32px;
}

.product-select .center .number-input input[type=number] {
  vertical-align: top;
  height: 25px;
  padding: 8px 20px;
}

.product-select .center div.placeholder::after {
  left: 100px;
  font-size: 14px;
}

.product-select .center .total {
  position: absolute;
  right: 200px;
  font-size: 24px;
  font-weight: 500;
}

.product-select .center .total span {
  font-size: 12px;
  margin-bottom: 5px;
}

div.points {
  height: 310px;
  width: 100%;
  background-color: #F2F2F2;
}

div.map {
  height: 100%;
  width: 100%;
  background: url("./../img/terkep3.svg");
  background-size: 100%;
  padding-top: 80px;
  padding-bottom: 200px;
  transition: 0.6s all;
}

div.map div.wrap {
  text-align: center;
}

div.map div.wrap div.topic {
  width: 940px;
  height: 630px;
  background-color: #FFFFFF;
  border-top: 1px solid #DC4228;
  padding: 70px 100px 70px 100px;
  display: block;
  display: none;
}

div.map div.wrap div.topic.show {
  display: block;
}

div.map div.wrap div.topic div.first-page-picture {
  width: 350px;
  height: 573px;
  background-color: purple;
  position: absolute;
  right: 80px;
  bottom: -120px;
  background: url("./../img/first_page_picture.jpg");
}

div.map div.wrap div.topic p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
  word-break: initial;
  width: auto;
  max-width: calc(100% - 400px);
}

div.map div.wrap div.first-page-button {
  display: flow-root;
  padding-top: 60px;
}

div.map div.wrap .nav-right {
  position: absolute;
  top: 0px;
  right: -160px;
}

div.map div.wrap .nav-left {
  position: absolute;
  top: 0px;
  left: -160px;
}

div.map div.wrap .nav-left .button.menu {
  text-align: left;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}

.canvas-container {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
}

.home {
  background-color: #E7E7E7;
}

.home .left,
.home .right {
  width: calc(50% - 5px);
  float: left;
  margin: 0;
  height: calc(100vh - 298px);
  background-position: center;
  background-size: cover;
  position: relative;
}

.home .left {
  background-image: url("./../img/home_steak.png");
  left: auto;
}

.home .left p {
  position: absolute;
  bottom: 100px;
  right: 50px;
  width: 40%;
  color: #FFFFFF;
  font-size: 18px;
  line-height: 28px;
  font-style: italic;
}

.home .left p a {
  display: block;
  width: 190px;
  margin: 20px 0 0 0;
  background-color: #FFFFFF;
  color: #DC4228;
  text-transform: uppercase;
  font-style: normal;
}

.home .right {
  float: right;
  background-image: url("./../img/home_graycattle.png");
}

.home .right h1 {
  color: #DC4228;
  font-size: 40px;
  line-height: 45px;
  padding: 50px;
  max-width: 320px;
}

@media screen and (max-width: 1500px) {
  .navbar {
    height: 100px;
  }

  .navbar ul.main-menu {
    padding-top: 40px;
  }

  .navbar ul.main-menu li {
    padding: 0 15px;
  }

  .navbar ul.lang-menu {
    padding-top: 40px;
    margin-left: 30px;
  }

  .navbar ul.customer-menu {
    margin-top: 40px;
  }

  .navbar a.navbar-brand {
    left: 40px;
    width: 220px;
  }

  footer {
    height: 100px;
  }

  footer .right,
  footer .center {
    line-height: 100px;
  }

  main {
    min-height: calc(100vh - 238px);
  }

  .canvas-container {
    margin: 20px auto;
  }

  div.map {
    padding-top: 50px;
  }

  div.map div.wrap div.topic {
    margin: auto;
    max-width: calc(100vw - 520px);
    position: relative;
    padding: 50px 70px;
  }

  div.map div.wrap div.topic p {
    font-size: 14px;
    line-height: 22px;
  }

  h1 {
    padding-bottom: 30px;
  }

  .home .right h1 {
    padding: 40px;
    font-size: 36px;
    max-width: 300px;
    line-height: 40px;
  }

  .home .left p {
    bottom: 50px;
    font-size: 16px;
    line-height: 22px;
  }
}

