div.dot {
	height: 98px;
	width: 98px;
	background-color: $light-gray;
	border-radius: 50%;
	border: 2px $light-gray solid;
	display: inline-block;
	margin-right: 25px;
	background-clip: content-box;
	background-repeat: no-repeat;
	background-size: 100%;
	background-position: center 0;
	vertical-align: top;
	text-align: center;

	&:hover {
		background-position: center -98px;
		cursor: pointer;
	}

	&.finished {
		background-image: url('../img/frozen.svg');
	}

	&.dry-age {
		background-image: url('../img/szarazerlelt.svg');
	}

	&.wet-age {
		background-image: url('../img/nedveserlelt.svg');	
	}

	&.frozen {
		background-image: url('../img/frozen.svg');		
	}		
	
	&.blonde {
		background-image: url('../img/blonde.svg');	
	}

	&.limousin {
		background-image: url('../img/limo.svg');
	}

	&.magyar {
		background-image: url('../img/szurke.svg');			
	}

	&.active {
		border: 2px $red solid;
		background-position: center -98px;

		&:hover{
			border: 2px $red solid;
		}
	}
}



