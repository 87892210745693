label.text-label {
	position: absolute;
	right: calc(100%  + 20px);
	line-height: 35px;
	width: 300px;
	text-align: right;
	font-size: 14px;
	text-transform: uppercase;
	color: $dark-silver;
	letter-spacing: 1px;
}