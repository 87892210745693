div.footer {
	width: 100%;
	height: 130px;
	background-color: $gray;
	position: absolute;
	display: block;
	text-align: center;

	div.copyright {
		position: relative;
		top: 50%;
		margin: auto;

		p {
			color: $footer-gray;
			font-size: 12px;

			b {
				color:  $footer-gray;
			}
		}
	}	

	div.text-logo {
		position: absolute;
		right: 60px;
		top: 40px;

		div.design {
			font-size: 10px;
			color: $font-gray;
			text-transform: uppercase;
			display: inline-block;
			padding-right: 20px;
			vertical-align: top;
			padding-top: 20px;
		}

		div.logo {
			width: 69px;
			height: 52px;
			background: url('../img/dyb.svg');
			display: inline-block;
		}
	}
}