@media screen and (max-width: 1500px) {

	.navbar {
		height: 100px;

		ul.main-menu {
			padding-top: 40px;

			li {
				padding: 0 15px;
			}
		}

		ul.lang-menu {
			padding-top: 40px;
			margin-left: 30px;
		}

		
		ul.customer-menu {
			margin-top: 40px;
		}

		a.navbar-brand {
			left: 40px;
			width: 220px;
		}
	}

	footer {
		height: 100px;

		.right,
		.center {
			line-height: 100px;
		}
	}

	main {
		min-height: calc(100vh - 238px);
	}

	.canvas-container {
		margin: 20px auto;
	}

	div.map {
		padding-top: 50px;
	}

	div.map div.wrap div.topic {
		margin: auto;
		max-width: calc(100vw - 520px);
		position: relative;
		padding: 50px 70px;

		p {
			font-size: 14px;
			line-height: 22px;
		}
	}

	h1 {
		padding-bottom: 30px;
	}

	.home {
		.right h1 {
			padding: 40px;
			font-size: 36px;
			max-width: 300px;
			line-height: 40px;
		}

		.left p {
			bottom: 50px;
			font-size: 16px;
    		line-height: 22px;
		}
	}
}