h1 {
	font-size: 26px;
	text-transform: uppercase;
	text-align: left;
	font-weight: bold;
	padding-bottom: 50px;
}

h2 {
	font-size: 12px;
	text-transform: uppercase;
	text-align: left;
	color: $dark-silver;
	font-weight: 100;
	padding: 0 0 25px 0;
}

h3 {
	font-size: 12px;
	color: $dark-silver;
	display: inline-block;
	margin-top: 110px;
}

h4 {
	font-size: 20px;
	color: $black;
	text-align: center;
	font-weight: bold;
	text-transform: uppercase;
	padding-top: 50px;
	padding-bottom: 50px;
}

h5 {
	font-size: 12px;
	text-transform: uppercase;
	text-align: left;
	color: $dark-silver;
	font-weight: 100;
	padding: 0 0 25px 0;
	padding-left: 64px;
}


p.text {
	width: 500px;
	text-align: left;
	display: inline-block;
	//padding: 0 0 20px 0;
	float: left;
	word-break: break-all;
	padding: 0;
	
}