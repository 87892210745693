.home {
	background-color: $background-gray;

	.left,
	.right {
		width: calc(50% - 5px);
		float: left;
		margin: 0;
		height: calc(100vh - 298px);
		background-position: center;
		background-size: cover;
		position: relative;
	}

	.left {
		background-image: url('../img/home_steak.png');
		left: auto;

		p {
			position: absolute;
			bottom: 100px;
			right: 50px;
			width: 40%;
			color: $white;
			font-size: 18px;
			line-height: 28px;
			font-style: italic;

			a {
				display: block;
				width: 190px;
				margin: 20px 0 0 0;
				background-color: $white;
				color: $red;
				text-transform: uppercase;
				font-style: normal;
			}
		}
	}

	.right {
		float: right;
		background-image: url('../img/home_graycattle.png');

		h1 {

			color: $red;
			font-size: 40px;
			line-height: 45px;
			padding: 50px;
			max-width: 320px;
		}
	}
}