table {
	//font-family: Open Sans;	
	font-size: 14px;
	letter-spacing: 1px;
	margin: auto;
}

th, td {
	padding: 5px;
	text-align: right;
}

th {
 	font-size: 12px;
	height: 35px;
	font-weight: 100;
	text-transform: uppercase;
 }

td {
	border-top: 1.5px solid $background-gray;
	vertical-align: middle;
	height: 40px;
} 

td:nth-child(1) {
	border-top: 1.5px solid $white;
	}

td:nth-child(2) {
	width: 200px;
	text-align: left;
}

td:nth-child(3) {
	width: 200px;
	text-align: center;
 }

th:nth-child(3) {
	text-align: center;
 }

td:nth-child(4) {
	width: 190px;
	text-align: right;
 }

.last-row {
	td { 
		background-color: $light-white;
		border-top: 23px solid $white;
		height: 47px;
		font-weight: 100;	
		font-size: 12px;
		padding-left: 0;
		padding-right: 14px;
		margin-top: 20px;
		text-transform: uppercase;
	}

	td:nth-child(4) {
		padding-right: 5px;
	}

	b {
		font-size: 16px;
	}
}