div.pink {
	background-color: pink;
	width: 100%;
}

div.gray {
	background-color: $background-gray;
	width: 100%;
	padding-top: 100px;
}

div.white-line {
	background-color: $white;
	width: 100%;
	height: 20px;
	border-top: 10px solid $background-gray;
}

div.white {
	background-color: $white;
	width: 100%;
	padding-top: 40px;
}