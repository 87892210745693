.info {
	font-size: 16px;

	td {
		height: 30px;
		padding: 5px;	
		line-height: 2;
		border-top: 1px solid $background-gray;
		margin: auto;
		letter-spacing: 0;
	}

	span {
		border-left: 1px solid $background-gray;
		font-weight: 600; 
		padding: 5px 0 5px 10px;
	}

	td:nth-child(1) {
		font-weight: 100;

	}
	
	tr.last-line {
		height: 49px;

		td {
			height: 49px;
			box-sizing: border-box;
			border-bottom: 1px solid $background-gray;
			//margin-bottom: 10px;
		}		
	}
}

.table-part-line {
	height: 30px;
	width: 1px;
	background-color: $background-gray;
}

		
