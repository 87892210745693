div.pre {
	height: 400px;
	position: relative;
}

div.gray-knife {
	background-color: $background-gray;
	width: 100%;
	min-height: 400px;
}

div.knife-description {
	padding: 60px;
	margin: auto;
	width: 800px;

	p.number-knife-one {
		display: inline-block;
		position: absolute;
		font-size: 30px;
		font-weight: 900;
	}

	p.text-knife {
		width: 170px;
		padding: 0 50px 0 38px;
		font-size: 12px;
		display: inline-block;
		text-align: top;
		float: left;
	}	
}

.timeline {
	position: relative;
	padding-top: 140px;
}

.month-bar {
	width: 1080px;
	padding: 0 0 60px 0;
	margin: auto;
	display: flex;
	flex-wrap: nowrap;

	.month {
		height: 28px;
		text-align: center;
		line-height: 25px;
		background-color: $stripe-gray;
		border-bottom: 3px solid $white;
		position: relative;	
		width: 100%;
		border-left: 2px solid $light-silver;

		span {
			text-transform: uppercase;
			font-size: 12px;
			color: $black;
			font-weight: 100;
			letter-spacing: 1px;
		}

		&:first-child {
			border-left: 0;
		}
	}
}

.select {

	div.knife {
			background: url('../img/kes.svg') 0 -67px;
			background-clip: content-box;
			background-repeat: no-repeat;
			background-size: cover;
	}

	div.cut-cow {
		background: url('../img/szurkemarha_vagni.svg') 0 -36px;
		background-clip: content-box;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

.cutting {
	position: absolute;
	display: block;
	top: 165px;
	margin-left: 20px;

	&:hover {
		div.knife {
			background: url('../img/kes.svg') 0 -67px;
			background-clip: content-box;
			background-repeat: no-repeat;
			background-size: cover;	
			position: absolute;
			display: block;
		}

		div.cut-card {
			display: block;
			margin-left: -60px;
			position: absolute;

		}

		div.arrow-up {
			display: block;
		}
	}
}

.knife {
	height: 67px;
	width: 20px;
	display: block;
	background: url('../img/kes.svg') 0 0;
	background-clip: content-box;
	background-repeat: no-repeat;
	background-size: cover;	
	position: absolute;
	bottom: 80px;
	left: -1px;
}

.mark {
	height: 9px;
	width: 2px;
	background-color: $silver;
	margin: 0 0 0 8px;
	display: block;
	position: absolute;
}

.cut-cow {
	height: 32px;
	width: 39px;
	display: block;
	background: url('../img/szurkemarha_vagni.svg') 0 0;
	background-clip: content-box;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	margin-top: 13px;
	left: -12px;
}

.white-bar {
	height: 3px;
	width: 50px;
	background-color: $white;
	margin: 50px 0 0 -17px;

	.red-bar {
		height: 100%;
		width: 50%;
		background-color: $red;
	}
}

.hover-cut-card {
	margin-top: -78px;
	display: block;
	position: absolute;
	z-index: 1;

	.arrow-up {
		display: none;
		margin: auto;
		width: 0; 
		height: 0; 
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;	  
		border-bottom: 10px solid $white;
		z-index: 1;
		position: relative;
	}

	.cut-card {
		font-size: 12px;
		color: $black;
		text-align: center;
		height: 195px;
		width: 146px;
		background-color: $white;
		display: none;
		filter: drop-shadow(0px 3px 6px rgba(60, 50, 0, 0.2));

		.white-bar {
			width: 100px;
			background-color: $bar-white;
			margin: auto;
			margin-bottom: 3px;
		}

		.cut-card-title {
			text-transform: uppercase;
			padding: 8px 8px 2px 8px;
			font-weight: 100;
		}

		.cut-card-cow {
			height: 50px;
			width: 60px;
			margin: auto;
			margin-bottom: 8px;
			background: url('../img/szurkemarha_vagni.svg') 0 -57px;
			background-clip: content-box;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.date {
			font-size: 16px;
			font-weight: bold;
		}

		.enar {
			font-size: 10px;
			margin: 3px 0 -11px 0;
		}

		.hu-number {
			font-size: 12px;
			text-transform: uppercase;
		}
	}
}

.two {
	margin: 0 100px 0 100px;
}

.three {
	margin: 0 0 0 50px;
}