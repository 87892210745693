input[type="number"] {
	-webkit-appearance: textfield;
	-moz-appearance: textfield;
	appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

.number-input {
  border: none;
  display: inline-flex;
}

.number-input button {
	outline:none;
	-webkit-appearance: none;
	background-color: transparent;
	border: none;
	cursor: pointer;
	margin: 0;
	font-size: 24px;
	color: $red;
	font-weight: 900;

	&:hover {
		color: $dark-red;
	}
}
/*
.number-input button:after {
	font-family: "Font Awesome 5 Free"; 
	content: "\f056";
}

.number-input button.plus:after {
	font-family: "Font Awesome 5 Free";  
	content: "\f055";
}*/

.number-input input[type=number] {	
	font-size: 14px;
	color: $red;
  	width: 47px;
	height: 12px;
	border: 1px solid $light-silver;
	padding: 8px;
	margin: 3px;
}

.placeholder {
    position: relative;
}

.placeholder::after {
	font-size: 10px;
	color: $dark-silver;
    position: absolute;
    right: 47px;
    top: 12px;
    content: attr(data-placeholder);
    pointer-events: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
	width: 100%;
	max-width: 400px;
	height: 35px;
	line-height: 35px;
	border: $border;
	padding: 0 10px;
	box-sizing: border-box;
	margin-bottom: 20px;
	font-weight: 700;
	letter-spacing: 0.5px;

	&:focus {
		border-color: $black;
	}
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

textarea {
	height: 105px;
	max-height: 105px;
	margin-bottom: 100px;
	padding: 10px;
	line-height: 20px;
	font-family: "Open Sans", sans-serif;

	&:focus {
		border:1px solid $black;
	}
}