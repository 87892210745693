div.popup {
	height: 650px;
	width: 940px;
	background-color: $white;
	border: 10px $background-gray solid;
	position: relative;
	
	.close {
		cursor: pointer;
		
		.x {
			height: 20px;
			width: 2px;
			margin: 20px 30px 0 0;  
			background-color: $silver;
			transform: rotate(45deg);
			float: right;
		}

		.xx {
			height: 20px;
			width: 2px;
			background-color: $silver;
			transform: rotate(90deg);
		}

		&:hover {
			.x,.xx {
				background-color: $black;
			}
		}
	}

	div.popup-cow {
		width: 623px; 
		height: 512px;
		position: relative;
		bottom: -10px;
		margin: auto; 

		&.parted-cow {
			background: url('../img/marha_magyar_bontas.svg');	
			background-clip: content-box;
			background-repeat: no-repeat;
			background-size: cover;	
		}
	}
}
