.contact {
	background-image: url(../img/contact.jpg);

	.center-center {
		background: $white;
		color: $black;
		text-align: left;
		padding: 30px;
		line-height: 30px;
		width: 300px;
		height: 210px;

		h1 {
			color: $black;
			text-align: left;
			font-size: 20px;
			letter-spacing: 1px;
			padding-bottom: 30px;
		}

		span {
			font-style: italic;
		}
	}
}