.navbar {
	height: 130px;
	background: $white;
	margin-bottom: 10px;

	a.navbar-brand {
		display: block;
		position: absolute;
		left: 100px;
		top: -24px;
		background: url('../img/logo.svg')no-repeat left top;
		background-size: contain;
		width: 250px;
		height: 120px;
	}

	ul.lang-menu {
		float: right;
		list-style: none;
		padding-top: 50px;
		margin-right: 40px;
		word-spacing: -4px;

		li {
			display: inline-block;
			border-right: 1px solid $light-silver;
			width: 38px;
			text-align: center;
			height: 18px;
			line-height: 18px;

			&:last-child {
				border: 0;
			}

			a {
				font-weight: 300;

				&.active {
					font-weight: 700;
					color: $red;
				}
			}
		}
	}

	ul.main-menu {
		list-style: none;
		text-align: center;
		padding-top: 50px;

		li {
			display: inline-block;
			padding: 0 35px;

			a {
				font-size: 16px;
				font-weight: 600;
				text-transform: uppercase;
				display: block;
				line-height: 26px;

				&.active,
				&:hover {
					color: $red;
				}
			}
		}
	}

	ul.customer-menu {
		float: right;
		list-style: none;
		margin-top: 50px;

		li a {
			color: $dark-silver;
		}
	}
}